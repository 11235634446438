import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { Menu } from "@blueprintjs/core/lib/esm/components/menu/menu";
import { MenuItem } from "@blueprintjs/core/lib/esm/components/menu/menuItem";
import { HotkeysProvider } from "@blueprintjs/core/lib/esm/context/hotkeys/hotkeysProvider";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { Table2, ColumnHeaderCell, Utils, Column, TableLoadingOption, EditableCell2, Cell, RenderMode, Region } from "@blueprintjs/table/lib/esm";
import "@blueprintjs/table/lib/css/table.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import { View, ViewColumn } from '../types/ViewTypes';
import { useSampleStore } from '../stores/SampleStore';
import { EditDataViewSelect } from './controls/EditDataViewSelect';
import { Sort, SortedColumn } from './DataViewerCtr';
import { getNestedChild } from '../services/domControl';
import { Popover2 } from "@blueprintjs/popover2";
import "../styles/EditDataViewer.css";
import { useUserStore } from '../stores/UserStore';
import { PipelineStatusIcon } from './PipelineStatusIcon';
import { InputFileLink, SampleMetaDataDetailListItem } from '../types/SampleTypes';
import { Tooltip2 } from "@blueprintjs/popover2/lib/esm/tooltip2"
import { useDataViewerCtrStore } from '../stores/DataViewerCtrStore';
import { FieldProps } from '../types/OrganismTypes';
import { useOrganismStore } from '../stores/OrganismStore';
import { useOpenComparisonsStore } from '../stores/OpenComparisonsStore';
import { useEditDataViewSelectStore } from '../stores/EditDataViewSelectStore';
import { OutbreakAPI } from '../api/DataAPI';
import { useAuth } from 'react-oidc-context';
import { useOutbreakDialogStore } from '../stores/OutbreakDialogStore';
import { ColumnIndices, RowIndices } from '@blueprintjs/table/lib/esm/common/grid';
import { useOrganizationStore } from '../stores/OrganizationStore';
import { Button, InputGroup } from '@blueprintjs/core';
import { getDateTimeString } from "../utils/getDateTimeString";
import { useOrganismFunctions } from '../hooks/useOrganismFunctions';
import { useSearchParams } from 'react-router-dom';

var cloneDeep = require('lodash.clonedeep');
// var dot = require('dot-object');

export type ICellLookup = (rowIndex: number, columnIndex: number) => any;
export type ICellSetter = (value: string, rowIndex: number, columnIndex: number, field: FieldRef) => void;
export type ISortCallback = (columnIndex: number, comparator: (a: any, b: any) => number, ascending: boolean) => void
export type CellEdits = { [key: string]: { value: string, path: string, rowIndex: number, error?: string, valid?: boolean } }
export type Group = { color: string, name: string }
export interface ISortableColumn {
  getColumn(
    getCellData: ICellLookup,
    setCellData: ICellSetter,
    sortColumn: ISortCallback,
    state: EditDataViewerState,
    activeCellEdits: CellEdits,
    colFieldRef: FieldRef[],
    activeShowSingleClickEdit: boolean,
    activeIndex: number,
    groups: Group[],
    currentSort: { index: number, order: "asc" | "desc" },
    setCurrentSort: ({ index, order }: { index: number, order: "asc" | "desc" }) => void,
  ): JSX.Element;
}

//for "ctrl+z", stores edit history
type EditHistoryType = { type: any, cellEdits: CellEdits, newData: CellEdits }[];
var editHistory: EditHistoryType = [];

//for "ctrl+y", stores edit history
var redoUndone_editHistory: EditHistoryType = [];

//for borders of the copied cells
export type copiedCellType = {
  leftmostIndex: number
  rightmostIndex: number
  topmostIndex: number
  bottommostIndex: number
}
var newValue = ""
var copiedCells: copiedCellType | null = null;

class SortableColumn implements ISortableColumn {
  protected name: string;
  protected data: SampleMetaDataDetailListItem[];
  protected updateSelections: (item: SampleMetaDataDetailListItem | null, id?: string) => void;
  protected selectSample?: (sampleId: string) => void;
  protected selectAll: boolean;
  protected handleSelectAllChange: () => void;
  protected selectedItems: Record<string, boolean>;
  protected handleCheckboxChange: (item: SampleMetaDataDetailListItem) => void;
  protected getIcon: (item: any) => void;
  protected getPublished: (item: any) => void;
  protected canEdit: boolean;
  protected descrip: string;
  protected selectedView: View | undefined
  protected ndbIndexedFields: string[] | undefined
  protected handleShowOutbreakForm: () => void
  protected sort: Sort | undefined
  protected setSort: (sort: Sort) => void
  protected setPreviousdata: (previousData: SampleMetaDataDetailListItem[] | undefined) => void
  protected path: string
  protected title: string
  protected columnFilters: { [key: string]: string }
  protected setColumnFilters: (columnFilters: { [key: string]: string }) => void
  protected isComparison: boolean
  protected viewDisplayed: string
  protected isSortedInDendrogram?: boolean
  protected setIsSortedInDendrogram?: (isSortedInDendrogram: boolean) => void
  constructor(name: string, protected index: number, data: SampleMetaDataDetailListItem[], updateSelections: (item: SampleMetaDataDetailListItem | null, id?: string) => void, selectSample: (sampleId: string) => void = () => { }, selectAll: boolean, handleSelectAllChange: () => void, selectedItems: Record<string, boolean>, handleCheckboxChange: (item: SampleMetaDataDetailListItem) => void, getIcon: (item: any) => void, getPublished: (item: any) => void, canEdit: boolean, descrip: string, selectedView: View | undefined, handleShowOutbreakForm: () => void, sort: Sort | undefined, setSort: (sort: Sort) => void, setPreviousdata: (previousData: SampleMetaDataDetailListItem[] | undefined) => void, path: string, title: string, columnFilters: { [key: string]: string }, setColumnFilters: (columnFilters: { [key: string]: string }) => void, ndbIndexedFields: string[], isComparison: boolean, viewDisplayed: string, isSortedInDendrogram?: boolean, setIsSortedInDendrogram?: (isSortedInDendrogram: boolean) => void) {
    this.name = name;
    this.data = data;
    this.updateSelections = updateSelections;
    this.selectSample = selectSample;
    this.selectAll = selectAll;
    this.handleSelectAllChange = handleSelectAllChange;
    this.selectedItems = selectedItems;
    this.handleCheckboxChange = handleCheckboxChange;
    this.getIcon = getIcon;
    this.getPublished = getPublished;
    this.canEdit = canEdit
    this.descrip = descrip
    this.path = path
    this.sort = sort
    this.setSort = setSort
    this.setPreviousdata = setPreviousdata
    this.selectedView = selectedView
    this.ndbIndexedFields = ndbIndexedFields
    this.handleShowOutbreakForm = handleShowOutbreakForm
    this.title = title
    this.columnFilters = columnFilters
    this.setColumnFilters = setColumnFilters
    this.isComparison = isComparison
    this.viewDisplayed = viewDisplayed
    this.isSortedInDendrogram = isSortedInDendrogram
    this.setIsSortedInDendrogram = setIsSortedInDendrogram
  }

  public getColumn(
    getCellData: ICellLookup,
    setCellData: ICellSetter,
    sortColumn: ISortCallback,
    state: EditDataViewerState,
    activeCellEdits: CellEdits,
    colFieldRef: FieldRef[],
    activeShowSingleClickEdit: boolean,
    activeIndex: number,
    groups: Group[],
  ) {

    const cellRenderer = (rowIndex: number, columnIndex: number) => {
      const field = colFieldRef[columnIndex]
      const dataKey = `${columnIndex}-${rowIndex}`
      const cellEdited = activeCellEdits[dataKey] !== undefined && activeCellEdits[dataKey].valid !== false
      const error = cellEdited && activeCellEdits[dataKey]?.error
      const shadeAlternatingRow = !cellEdited && rowIndex & 1
      const cellBackgroundColorStyle = shadeAlternatingRow ? "#E5E8EB" : undefined
      const intent = error ? Intent.DANGER : cellEdited ? Intent.WARNING : undefined

      const handleClick = () => {
        const sortedRowIndex = rowIndex;
        const targetID = this.data[sortedRowIndex].id;
        if (targetID)
          this.updateSelections(null, targetID);
      };

      // disable editing cells for published row for PN and confirmed row for CN
      let disableEdit = false
      if ((!this.data[rowIndex]?.organization?.toLowerCase().includes("calicinet") && this.data[rowIndex]?.published && field.access !== "lab_only_field") ||
          (this.data[rowIndex]?.organization?.toLowerCase().includes("calicinet") && this.data[rowIndex]?.confirmed && field.access !== "lab_only_field"))
        disableEdit = true

      const canEdit = () => {
        if (disableEdit)
          return false
        else
          return this.canEdit
      }

      if (this.name === "LabOBNumber") {
        return (
          <Cell loading={false} interactive={true} style={{ backgroundColor: cellBackgroundColorStyle }}>
            <div onClick={handleClick} onDoubleClick={this.handleShowOutbreakForm} style={{ width: "100%", minHeight: "20px", }}>
              {getCellData(rowIndex, columnIndex)}
            </div>
          </Cell>
        )
      }

      if (["Checkbox", "FileLinks", "_Status", "Published", "Confirmed", "LabOBNumber", "_NCBIStatus"].includes(field.name))
        return <div
          className='outerBorder-checkbox__pipeline__publish'
          onClick={handleClick}
          tabIndex={0}
        >
          <Cell
            loading={state.loadingCells.includes(dataKey)}
            intent={intent}
            style={this.isComparison ? { height: '30px', paddingTop: '5px' } : { backgroundColor: cellBackgroundColorStyle }}
          >
            {getCellData(rowIndex, columnIndex)}
          </Cell>
        </div>
      if (field.enum) {
        return (
          canEdit()
            ? <Cell
              loading={state.loadingCells.includes(dataKey)}
              intent={intent} tabIndex={0}
              style={{ backgroundColor: cellBackgroundColorStyle, height: this.isComparison ? '30px' : undefined }}
            >
              <div style={{ width: '100%', height: '100%' }} onClick={handleClick}>
                <EditDataViewSelect
                  columnIndex={columnIndex}
                  rowIndex={rowIndex}
                  getCellData={getCellData}
                  setCellData={setCellData}
                  values={field.enum}
                  field={field}
                  resetBorders={resetBorders}
                  copiedCells={copiedCells}
                  activeIndex={activeIndex}
                  activeShowSingleClickEdit={activeShowSingleClickEdit}
                />
                {getCellData(rowIndex, columnIndex)}
              </div>
            </Cell>
            : <Cell
              tabIndex={0}
              loading={state.loadingCells.includes(dataKey)}
              intent={intent}
              style={{ backgroundColor: cellBackgroundColorStyle }}
            >
              <div onClick={handleClick}>
                {getCellData(rowIndex, columnIndex)}
              </div>
            </Cell>
        );
      } else if (field.name === 'Key') {
        // Handle double click for the Key column
        const handleDoubleClick = () => {
          if (this.selectSample) {
            const sampleId = this.data[rowIndex].id;
            this.selectSample(sampleId);
          }
        };
        const createdDate = this.data[rowIndex]["createdAt"] && new Date(this.data[rowIndex]["createdAt"])

        return (
          <Cell
            tabIndex={0}
            loading={state.loadingCells.includes(dataKey)}
            intent={intent}
            style={this.isComparison ? { height: '30px', paddingTop: '5px', backgroundColor: cellBackgroundColorStyle } : { backgroundColor: cellBackgroundColorStyle }}
          >
            <div onClick={handleClick} onDoubleClick={handleDoubleClick} style={{ cursor: "pointer" }} title='Double click to view sample details.'>
              <span style={{ fontWeight: 500 }}>{getCellData(rowIndex, columnIndex)}</span>
              {
                (!this.isComparison && createdDate && new Date().getTime() - createdDate.getTime() <= 3600000) &&
                (
                  <Icon icon="clean" />
                )
              }
            </div>
          </Cell>
        );
      } else {
        if (activeShowSingleClickEdit) {
          if (field.name === "Checkbox" || field.name === "_Status" || field.name === "Published" || field.name === "FileLinks" || field.name === "_NCBIStatus") {
            return (
              <div onClick={handleClick}>
                <EditableCell2
                  tabIndex={0}
                  value={getCellData(rowIndex, columnIndex)}
                  onConfirm={(v) => setCellData(v, rowIndex, columnIndex, field)}
                  loading={state.loadingCells.includes(dataKey)}
                  intent={intent}
                  tooltip={error ? error : undefined}
                  style={this.isComparison ? { height: '30px', paddingTop: '5px', backgroundColor: cellBackgroundColorStyle } : { backgroundColor: cellBackgroundColorStyle }}
                >
                  {getCellData(rowIndex, columnIndex)}
                </EditableCell2>
              </div>
            )
          } else {
            return (
              <Cell
                tabIndex={0}
                loading={state.loadingCells.includes(dataKey)}
                intent={intent}
                style={this.isComparison ? { height: '30px', backgroundColor: cellBackgroundColorStyle } : { backgroundColor: cellBackgroundColorStyle }}
              >
                <div style={{ position: 'relative', height: this.isComparison ? '30px' : undefined }}>
                  <div style={{ visibility: 'hidden', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    <input
                      type="text"
                      defaultValue={getCellData(rowIndex, columnIndex)}
                      onBlur={(e) => {
                        setCellData(e.target.value, rowIndex, columnIndex, field);
                        const currentDiv = e.currentTarget as HTMLDivElement;
                        const currentDivParentElement = currentDiv.parentElement as HTMLDivElement;
                        if (currentDivParentElement) {
                          currentDivParentElement.style.visibility = 'hidden';
                        }

                        const nextSibling = currentDiv.parentElement?.nextSibling as HTMLDivElement;
                        if (nextSibling) {
                          nextSibling.style.visibility = 'visible';
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const target = e.target as HTMLInputElement;
                          setCellData(target.value, rowIndex, columnIndex, field);
                          target.blur();
                        }
                      }}
                      style={{ width: '100%', boxSizing: 'border-box', height: '100%' }}
                    />
                  </div>
                  <div onClick={(e) => {
                    if (!canEdit() || field.name === "GroupName") return;
                    const currentDiv = e.currentTarget as HTMLDivElement;
                    currentDiv.style.visibility = 'hidden';

                    const previousSibling = currentDiv.previousSibling as HTMLDivElement;
                    if (previousSibling) {
                      previousSibling.style.visibility = 'visible';
                      const inputElement = previousSibling.firstChild as HTMLInputElement;
                      if (inputElement) {
                        inputElement.focus();
                      }
                    }
                  }}
                    style={{
                      minHeight: '20px',
                      minWidth: '20px',
                      paddingTop: '5px',
                      color: groups.length > 0 ? groups.find(group => group.name === getCellData(rowIndex, columnIndex))?.color : 'black'
                    }}
                  >
                    {getCellData(rowIndex, columnIndex) || ' '}
                  </div>
                </div>
              </Cell>
            );
          }
        } else {
          return (
            canEdit() && field.name !== "GroupName"
              ? <div onClick={handleClick}>
                <EditableCell2
                  tabIndex={0}
                  value={getCellData(rowIndex, columnIndex)}
                  onConfirm={(v) => {
                    setCellData(newValue, rowIndex, columnIndex, field)
                    newValue = ""
                  }}
                  onChange={(v) => {newValue = v}}
                  loading={state.loadingCells.includes(dataKey)}
                  intent={intent}
                  tooltip={error ? error : undefined}
                  className="cellContainerStyle"
                  style={{ backgroundColor: cellBackgroundColorStyle }}
                >
                  {getCellData(rowIndex, columnIndex)}
                </EditableCell2>
              </div>
              : <Cell
                tabIndex={0}
                loading={state.loadingCells.includes(dataKey)}
                intent={intent}
                style={this.isComparison ? { height: '30px', paddingTop: '5px', backgroundColor: cellBackgroundColorStyle } : { backgroundColor: cellBackgroundColorStyle }}
              >
                <div onClick={handleClick} style={{ color: groups.length > 0 ? groups.find(group => group.name === getCellData(rowIndex, columnIndex))?.color : 'black' }}>
                  {getCellData(rowIndex, columnIndex)}
                </div>
              </Cell>
          );
        }
      }
    };

    const menuRenderer = this.renderMenu.bind(this, sortColumn);
    const sortIcon = this.sort?.order === "asc" ? "arrow-up" : "arrow-down"
    const columnHeaderCellNameRenderer = (name: string, index?: number | undefined) => <div style={{ overflowX: "hidden" }}>{name}{(this.viewDisplayed === "Dendrogram" ? this.isSortedInDendrogram : true) && this.index === this.sort?.index && <Icon size={15} style={{ marginLeft: "5px" }} color='#5f6b7c' icon={sortIcon} />}{Object.keys(this.columnFilters).includes(`${this.path}`) && <Icon size={15} style={{ marginLeft: "5px" }} color='#5f6b7c' icon={"filter"} />} </div>
    let columnHeaderCellRenderer;
    if (this.name === "Checkbox") {
      columnHeaderCellRenderer = () => <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          <Tooltip2 content="select/deselect all">
            <input
              alt='checkbox to select/deselect all samples'
              type="checkbox"
              checked={this.selectAll}
              onChange={this.handleSelectAllChange}
            />
          </Tooltip2>
        </div>
      </ColumnHeaderCell>;
    } else if (this.name === "_Status") {
      columnHeaderCellRenderer = () => <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          <Tooltip2 content="Pipeline Status">
            <Icon icon="flows" />
          </Tooltip2>
        </div>
      </ColumnHeaderCell>;
    } else if (this.name === "Published") {
      columnHeaderCellRenderer = () => <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          <Tooltip2 content="Publication Status">
            <Icon icon="book" />
          </Tooltip2>
        </div>
      </ColumnHeaderCell>;
    } else if (this.name === "FileLinks") {
      columnHeaderCellRenderer = () => <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          <Tooltip2 content="Sequence Files">
            <Icon icon="document" />
          </Tooltip2>
        </div>
      </ColumnHeaderCell>;
    } else if (this.name === "Confirmed") {
      columnHeaderCellRenderer = () => <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          <Tooltip2 content="Confirmation Status">
            <Icon icon="confirm" />
          </Tooltip2>
        </div>
      </ColumnHeaderCell>;
    } else if (this.name === "_NCBIStatus") {
      columnHeaderCellRenderer = () => <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          <Tooltip2 content="NCBI Upload Status">
            <Icon icon="archive" />
          </Tooltip2>
        </div>
      </ColumnHeaderCell>;
    } else if (!this.selectedView?.isOutbreak && this.name === "LabOBNumber") {
      columnHeaderCellRenderer = () => <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          LabOBNumber
        </div>
      </ColumnHeaderCell>;
    }
    else {
      columnHeaderCellRenderer = () => {
        return <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined}>
          <Tooltip2 className='tool-tip-grid-headers' content={this.descrip}>
            <ColumnHeaderCell style={this.isComparison ? { height: '57px' } : undefined} name={this.title} nameRenderer={columnHeaderCellNameRenderer} menuRenderer={menuRenderer}>

            </ColumnHeaderCell>
          </Tooltip2>
        </ColumnHeaderCell>
      };
    }
    return (
      <Column
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={columnHeaderCellRenderer}
        key={this.index}
        name={this.name}
      />
    );
  }

  protected showSort() {
    return !this.selectedView?.nationalDatabase || this.ndbIndexedFields?.includes(this.name) || this.isComparison
  }

  protected renderMenu(sortColumn: ISortCallback) {
    const sortAsc = () => {
      this.setPreviousdata(this.data)
      this.setSort({ field: `+${this.path}`, index: this.index, order: "asc" })
      if (this.viewDisplayed === "Dendrogram") {
        this.setIsSortedInDendrogram?.(true)
      }
      sortColumn(this.index, (a: any, b: any) => 1, true)
    }
    const sortDesc = () => {
      this.setPreviousdata(this.data)
      this.setSort({ field: `-${this.path}`, index: this.index, order: "desc" })
      if (this.viewDisplayed === "Dendrogram") {
        this.setIsSortedInDendrogram?.(true)
      }
      sortColumn(this.index, (a: any, b: any) => 1, true)
    }
    return (
      <Menu >
        {/* only show sort for NDB if indexed columns */}
        {this.showSort() && <MenuItem icon="sort-asc" onClick={sortAsc} text="Sort Asc" />}
        {this.showSort() && <MenuItem icon="sort-desc" onClick={sortDesc} text="Sort Desc" />}
        <InputGroup
          defaultValue={this.columnFilters[this.path] || ""}
          leftIcon="filter"
          id={`${this.path}-filter`}
          onKeyDown={(event) => {
            event.stopPropagation()
            const target = event.target as HTMLInputElement
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              if (target.value === "") {
                delete this.columnFilters[this.path]
              } else {
                this.columnFilters[this.path] = target.value
              }

              this.setColumnFilters({ ...this.columnFilters })
            }
          }}
          rightElement={
            <Button
              minimal
              icon="cross"
              onClick={(event) => {
                delete this.columnFilters[this.path]
                this.setColumnFilters({ ...this.columnFilters })
                const inputElement = document.getElementById(`${this.path}-filter`) as HTMLInputElement
                if (inputElement) {
                  inputElement.value = ""
                }
              }}
            />
          }
          onBlur={(event) => {
            if (event.target.value === "") {
              delete this.columnFilters[this.path]
            } else {
              this.columnFilters[this.path] = event.target.value
            }

            this.setColumnFilters({ ...this.columnFilters })
          }}
        />
      </Menu >
    );
  }

  private compare(a: any, b: any) {
    return a.toString().localeCompare(b);
  }
}

interface EditDataViewerProps {
  tableId?: string
  data: SampleMetaDataDetailListItem[],
  leftSampleData?: SampleMetaDataDetailListItem[],
  showLoading?: boolean
  refetchOnSort?: (sortedColumn: SortedColumn) => Promise<void>
  fetchNextOnScroll?: () => void
  selectedView?: View
  // setSharedSelectedSampleIds: (sharedSelectedSampleIds: string[]) => void
  isFetching?: boolean
  previousNumberOfEditDataViewerTable: number
  setPreviousNumberOfEditDataViewerTable: (previousNumberOfEditDataViewerTable: number) => void
  queryIsLoading: boolean
  queryHasNextPage: boolean
  queryIsFetching: boolean
  queryFetchNextPage: any
  selectSample: (sampleId: string) => void
}

interface EditDataViewerState {
  sortedIndexMap: number[];
  loadingCells: string[];
}

export interface FieldRef extends FieldProps {
  name: string
  required: boolean
}

type cellType = {
  cols: [number, number]
  rows: [number, number]
}

type selectedRange = {
  startIndex: number
  endIndex: number
}

let editDataTableElement: HTMLElement;
let activeIndex: number;
let dataForPreviousData: SampleMetaDataDetailListItem[];

function EditDataViewer({
  tableId,
  data,
  leftSampleData,
  showLoading,
  selectedView,
  queryIsLoading,
  queryHasNextPage,
  queryIsFetching,
  queryFetchNextPage,
  previousNumberOfEditDataViewerTable,
  setPreviousNumberOfEditDataViewerTable,
  selectSample }: EditDataViewerProps) {
  // update timestamp : {YYYY-MM-DD}T{HH:MM:SS}.XXXZ -> {YYYY-MM-DD} {HH:MM:SS}
  data = useMemo(() => {
    let updatedData = data.map(item => {
      ['publishedAt', 'audit.createdAt', 'audit.updatedAt', 'createdAt', 'updatedAt'].forEach(key => {
        if (item[key]) {
          item[key] = getDateTimeString(item[key])
        }
      });
      return item
    })

    if (leftSampleData && leftSampleData.length > 0)
      updatedData = [...updatedData, ...leftSampleData]

    dataForPreviousData = updatedData
    return updatedData
  }, [data, leftSampleData]);
  const tableRef = useRef<Table2 | null>(null);
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const hiddenMultiInputRef = useRef<HTMLInputElement | null>(null);
  const currentCellIndicesRef = useRef<{ rowIndex: number | null; columnIndex: number | null }>({ rowIndex: null, columnIndex: null });
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const [focusedCell, setFocusedCell] = useState<{ rowIndex: number | null; columnIndex: number | null }>({ rowIndex: null, columnIndex: null });
  const focusedCellsRef = useRef<cellType[] | null>([]);
  const isShiftPressedRef = useRef<boolean>(false);
  const isAltPressed = useRef<boolean>(false);
  const lastSelectedIndex = useRef<number>(-1);
  const lastSelectedRangeShiftKey = useRef<selectedRange>({ startIndex: -1, endIndex: -1 });
  const [, forceUpdate] = useState<number>();
  const { selectedSampleIds, setSelectedSampleIds, setFocusedSampleId, cellEdits, focusedSampleId, columnFilters, setColumnFilters, isExportDialogOpen, totalSampleIdList } = useDataViewerCtrStore()
  const { setSelectedOutbreak, setShowOutbreakForm } = useOutbreakDialogStore()
  const { samples } = useSampleStore()
  const { user } = useUserStore()
  const { organism } = useOrganismStore()
  const { organization } = useOrganizationStore()
  const { setCellEdits, setUnselectafterdelete, unselectafterdelete, showSingleClickEdit, newEdits, setNewEdits, sort, setSort, previousData, setPreviousData, updatedKeysForEditing, setUpdatedKeysForEditing } = useDataViewerCtrStore()
  //comparisonview
  const { setCellEdits: setCellEditsComparison, setUnselectafterdelete: setUnselectafterdeleteComparison, setNewEdits: setNewEditsComparison, selectedTabId, getTabById, setSort: setSortForComparison, previousData: previousDataForComparison, setPreviousData: setPreviousDataForComparison, setIsSortedInDendrogram } = useOpenComparisonsStore()
  const { setShowSingleclickedit, setIsComparison } = useEditDataViewSelectStore()
  const isComparison = !selectedView
  const currentTab = getTabById(selectedTabId)
  const auth = useAuth()
  const outbreakAPI = new OutbreakAPI(auth.user?.access_token || "")
  let cellEditsComparison: CellEdits = {}
  let unselectafterdeleteComparison: boolean = false
  let showSingleClickEditComparison: boolean = false
  let newEditsComparison: string[] = []
  const [isSortingBySelected, setIsSortingBySelected] = useState(false)
  const isKeyPressHandledRef = useRef(false);
  const activeShowSingleClickEdit = useRef(true);
  const { getViewableFields, canEditField } = useOrganismFunctions()
  const [searchParams] = useSearchParams()
  const fullComparison = searchParams.get("fullComparison") || false

  // const copiedCells = useRef<{ columnStart: number, columnEnd: number, rowStart: number, rowEnd: number } | undefined>()
  const updateHasRunForShift = useRef(false)

  const handleShowOutbreakForm = async () => {
    if (focusedSampleId) {
      const focusedOutbreak = selectedView?.nationalDatabase ? await outbreakAPI.getNdbById(focusedSampleId) : await outbreakAPI.get(focusedSampleId)
      setSelectedOutbreak(focusedOutbreak)
      setShowOutbreakForm(true)
    }
  }
  if (isComparison && currentTab) {
    const { cellEdits, unselectafterdelete, showSingleClickEdit, newEdits } = currentTab
    cellEditsComparison = cellEdits
    unselectafterdeleteComparison = unselectafterdelete
    showSingleClickEditComparison = showSingleClickEdit
    newEditsComparison = newEdits
  }

  // Set Default Sort
  useEffect(() => {
    const col = getViewableColumns.find(v => v.sorted)   
    if(col){    
      const index = getViewableColumns.map(v => v.name).indexOf(col.name)
      if (col && col.sorted === "asc") {        
        if (isComparison && currentTab)
          setSortForComparison({ field: `+${col.path}`, index: index + 1, order: "asc" })
        else
          setSort({ field: `+${col.path}`, index: index + 1, order: "asc" })
      } else if (col.sorted === "desc") {
        if (isComparison && currentTab)
          setSortForComparison({ field: `-${col.path}`, index: index + 1, order: "desc" })
        else
          setSort({ field: `-${col.path}`, index: index + 1, order: "desc" })
      }
    } 
    else {
      if(!isComparison){
        const defaultColumn = getViewableColumns[0] ?? fieldList[0]
        if(defaultColumn)
          setSort({ field: `+${defaultColumn.path}`, index: 0 + 1, order: "asc" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView])

  //when View is changed to Dendrogram, Sort should be reset to default
  useEffect(() => {
    if (currentTab?.viewDisplayed !== "Dendrogram") {
      //when View is not Dendrogram, "isSortedInDendrogram" should be reset to "false"
      setIsSortedInDendrogram?.(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab?.viewDisplayed, currentTab?.comparison?.mlstScheme, currentTab?.comparison?.dendrogramAlgorythm, currentTab?.comparison?.dendrogramDistance])

  const activeCellEdits = !isComparison ? cellEdits : cellEditsComparison
  const activeUnselectafterdelete = !isComparison ? unselectafterdelete : unselectafterdeleteComparison
  activeShowSingleClickEdit.current = !isComparison ? showSingleClickEdit : showSingleClickEditComparison
  const activeNewEdits = !isComparison ? newEdits : newEditsComparison
  const groups = currentTab?.comparison?.groups ?? []
  const getStatusFields = () => organization?.organizationName.toLowerCase().includes("calicinet") ? (selectedView?.isOutbreak ? ["data.metadata.Published"] : (selectedView?.isOutbreak ? ["data.metadata.Published"] : ["inputFileLinks", "data.metadata._Status", "data.metadata.Published", "data.metadata.Confirmed", "data.metadata.LabOBNumber"])) : ["inputFileLinks", "data.metadata._Status", "data.metadata.Published", "data.metadata._NCBIStatus"]
  const getStatusFieldsForNDB = () => organization?.organizationName.toLowerCase().includes("calicinet") ? ((selectedView?.isOutbreak || isComparison) ? [] : ["data.metadata.Confirmed", "data.metadata.LabOBNumber"]) : []

  const canEdit = useMemo(() => {
    return isComparison ? true : selectedView?.nationalDatabase ? !!user?.role.includes("DatabaseManager") : true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComparison, selectedView?.nationalDatabase, user?.role])

  if (!organism)
    throw (new Error("Organism is undefined."))
  const fieldList = currentTab?.comparison.columns.map((col) =>
  ({
    path: col.path,
    name: col.name
  }
  )) || []

  if (data.some(v => v['data.metadata.GroupName'] !== undefined))
    fieldList.splice(0, 0, { path: 'data.metadata.GroupName', name: 'GroupName' } as ViewColumn)

  const [selectAll, setSelectAll] = useState(false);
  // to generates a new SortableColumn class instance only for columns of edited cells. 
  const [tempColumns, setTempColumns] = useState<any[]>([]);

  const selectedItems = useMemo(() => {
    return Object.fromEntries(totalSampleIdList.map(item => [item, selectedSampleIds.includes(item)])) as Record<string, boolean>
  }, [totalSampleIdList, selectedSampleIds]);

  const setSelectedItems = (selectedItems: Record<string, boolean>) => {
    const sampleIds = Object.entries(selectedItems).filter(v => v[1]).map(v => v[0])
    setSelectedSampleIds(sampleIds)
  }

  const handleSelectAllChange = () => {
    lastSelectedIndex.current = -1
    lastSelectedRangeShiftKey.current = { startIndex: -1, endIndex: -1 };

    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newSelectedItems = data.reduce((acc, item) => {
      acc[item.id] = newSelectAll;
      return acc;
    }, {} as Record<string, boolean>);
    setSelectedItems(newSelectedItems);

    // Update the list of selected sample IDs
    const selectedIds = newSelectAll ? data.map(item => item.id) : [];

    // Update the focused sample ID
    if (!isComparison)
      setFocusedSampleId(selectedIds.length ? selectedIds[selectedIds.length - 1] : undefined);
  };

  const handleCheckboxChange = (item: SampleMetaDataDetailListItem) => {
    updateSelections(item);
  };

  const updateSelections = (item: SampleMetaDataDetailListItem | null, id?: string, shiftOverride?: boolean) => {
    const selectedID = id ? id : item?.id
    if (selectedID) {
      const isItemChecked = !selectedItems[selectedID];
      const sortedIndices: number[] = tableRef?.current?.props?.cellRendererDependencies?.[0]
      const isSorted = sortedIndices.length > 0;
      let isAllChecked = true;
      if (isShiftPressedRef.current || shiftOverride) {
        updateHasRunForShift.current = true
        // when 'shift' key is pressed

        //determine Min and Max Indices of selected IDs
        let minIndex = Infinity, maxIndex = -Infinity
        selectedSampleIds.forEach((id: string) => {
          let index = data.map(i => i.id).findIndex(i => i === id)
          minIndex = Math.min(minIndex, index)
          maxIndex = Math.max(maxIndex, index)
        })

        //select All Between Min and Max Indices Upon New Selection and between last selected index and current selected index
        const updatedSelectedItems = { ...selectedItems };
        const currentIndex = isSorted ? sortedIndices.findIndex(i => data[i].id === selectedID) : data.map(i => i.id).findIndex(i => i === selectedID)
        const startIndex = lastSelectedIndex.current !== -1 ? Math.min(lastSelectedIndex.current, currentIndex) : Math.min(minIndex, currentIndex);
        const endIndex = lastSelectedIndex.current !== -1 ? Math.max(lastSelectedIndex.current, currentIndex) : Math.max(maxIndex, currentIndex);

        //to prevent the issue of not selecting the checkbox in the selected range when selecting a lower-index row after selecting a later-index row clicking the 'Shift' key.
        if (currentIndex === lastSelectedIndex.current && currentIndex === lastSelectedRangeShiftKey.current.startIndex) {
          return
        }

        // If all items within the range are already selected, deselect them

        // if at least one item in the range is unchecked, then it won't be 'deselected'
        if (isAllChecked) {
          for (let i = startIndex; i <= endIndex; i++) {
            if (i !== lastSelectedIndex.current) {
              const itemId = isSorted ? data[sortedIndices[i]]?.id : data.map(i => i.id)[i];
              if (itemId && selectedItems[itemId] === false) isAllChecked = false
            }
          }
        }
        // If `lastSelectedIndex.current` is -1, it could be deselecting the previous range.
        // Verify if this action toggles the selection based on the last row's opposite value.
        if (isAllChecked === false && lastSelectedIndex.current === -1 && currentIndex === lastSelectedRangeShiftKey.current.endIndex) {
          isAllChecked = true
        }
        // Update selected items within the range
        if (isAllChecked === true && currentIndex === lastSelectedRangeShiftKey.current.endIndex) {
          // toggling based on the opposite value of the last row selected
          const currentItemId = isSorted ? data[sortedIndices[currentIndex]]?.id : data.map(i => i.id)[currentIndex];
          const isChecked = selectedItems[currentItemId]
          for (let i = lastSelectedRangeShiftKey.current.startIndex; i <= endIndex; i++) {
            const itemId = isSorted ? data[sortedIndices[i]]?.id : data.map(i => i.id)[i];
            //false : deselect
            //true : select
            if (itemId) updatedSelectedItems[itemId] = !isChecked;
          }
        } else {
          for (let i = startIndex; i <= endIndex; i++) {
            const itemId = isSorted ? data[sortedIndices[i]]?.id : data.map(i => i.id)[i];
            //false : deselect
            //true : select
            if (itemId) updatedSelectedItems[itemId] = isAllChecked ? false : true;
          }
          lastSelectedRangeShiftKey.current = { startIndex: startIndex, endIndex: endIndex };
        }

        setSelectedItems(updatedSelectedItems);

      } else {
        isAllChecked = false
        // Without holding the Shift key, checkbox toggles only when clicking 'Checkbox' not inside cells
        if (!id) {
          const newSelected = { ...selectedItems, [selectedID]: isItemChecked } as Record<string, boolean>;
          setSelectedItems(newSelected);
        }
      }

      if (isAllChecked) {
        // After deselect all items within the range, reset lastSelectedIndex 
        lastSelectedIndex.current = -1
      } else {
        const index = data.map(i => i.id).findIndex(i => i === selectedID)
        const sortedIndex = isSorted ? sortedIndices.findIndex((element: number) => element === index) : index
        lastSelectedIndex.current = sortedIndex
      }

      // Update the list of selected sample IDs
      // const selectedIds = Object.keys(newSelected).filter(id => newSelected[id]);
      // setSelectedSampleIds(selectedIds);

      // Update the focused sample ID
      if (!isComparison)
        setFocusedSampleId(isItemChecked ? selectedID : undefined);

      // Update the list of shared sample IDs - view <-> comparison
      // setSharedSelectedSampleIds(selectedIds)
    }
  };

  const updateCellEdits = (previousData: SampleMetaDataDetailListItem[] ) => {
    let previousSortedData: SampleMetaDataDetailListItem[] = [];
    let sortedData: SampleMetaDataDetailListItem[] = [];
    if (isSortingBySelected) {
      previousSortedData = [...previousData]
      previousSortedData.sort((a, b) => {
        if (selectedSampleIds.includes(a.id) && !selectedSampleIds.includes(b.id))
          return -1
        else if (selectedSampleIds.includes(a.id) && selectedSampleIds.includes(b.id))
          return 0
        else if (selectedSampleIds.includes(b.id) && !selectedSampleIds.includes(a.id))
          return 1
        else return 0
      })
      sortedData = [...data]
      sortedData.sort((a, b) => {
        if (selectedSampleIds.includes(a.id) && !selectedSampleIds.includes(b.id))
          return -1
        else if (selectedSampleIds.includes(a.id) && selectedSampleIds.includes(b.id))
          return 0
        else if (selectedSampleIds.includes(b.id) && !selectedSampleIds.includes(a.id))
          return 1
        else return 0
      })
    }
    let previous = isSortingBySelected ? previousSortedData : previousData
    let current = isSortingBySelected ? sortedData : data

    const previousIdentifiers = previous.map(item => item.identifier);
    const currentIdentifiers = current.map(item => item.identifier);
  
    let isEqual = true;
    let changes: { identifier: string, previousIndex: number, currentIndex: number }[] = [];
  
    previousIdentifiers.forEach((identifier, index) => {
      const currentIndex = currentIdentifiers.indexOf(identifier);
  
      if (currentIndex !== index) {
        isEqual = false;
        if(currentIndex !== -1)
          changes.push({ identifier, previousIndex: index, currentIndex });
      }
    });
  
    //Update cell edits based on changes in rowIndex
    if (!isEqual) {
      let newCellEdits = cloneDeep(activeCellEdits);
  
      // to only proceed if this key hasn't been updated yet
      const updatedKeys = new Set(!isComparison ? updatedKeysForEditing.keys: null);
      let isUpdated = false;

      changes.forEach(change => {
        Object.keys(newCellEdits).forEach(dataKey => {
          const [columnIndex, rowIndex] = dataKey.split('-').map(Number);
  
          if (!updatedKeys.has(dataKey) && rowIndex === change.previousIndex) {
            const newDataKey = `${columnIndex}-${change.currentIndex}`;
            newCellEdits[newDataKey] = {
              ...newCellEdits[dataKey],
              rowIndex: change.currentIndex,
            };
  
            updatedKeys.add(dataKey);
            updatedKeys.add(newDataKey);
            isUpdated = true;

            // Delete the old entry
            delete newCellEdits[dataKey];
          }
        });
      });

      //to ensure that only fully updated cells are visible
      Object.keys(newCellEdits).forEach(dataKey => {
        if(updatedKeys.has(dataKey))
          newCellEdits[dataKey].valid = true
        else
          if(data.length > 500) newCellEdits[dataKey].valid = false
      })

      let count = 0;
      Object.keys(newCellEdits).forEach(dataKey => {
        if(data.length < 500 && data.length <= dataKey.split('-').map(Number)[1]){
          count++
        }
      })

      //once "editCells" has been fully updated, reset related states
      if(Object.keys(newCellEdits).length * 2 <= (updatedKeys.size + count*2)){
        if(!isComparison){
          setPreviousData(undefined)
          setUpdatedKeysForEditing({keys : [], needUpdate : true})
        } else {
          setPreviousDataForComparison(undefined)
        }
      } else {
        if(queryHasNextPage && isUpdated && !isComparison)
          setUpdatedKeysForEditing({keys : Array.from(updatedKeys) as string[], needUpdate : false})
      }
      if(!isComparison ? JSON.stringify(newCellEdits) !== JSON.stringify(cellEdits) : JSON.stringify(newCellEdits) !== JSON.stringify(cellEditsComparison))
        !isComparison ? setCellEdits(newCellEdits) : setCellEditsComparison(newCellEdits)
    }
  }

  const getPublished = (item: any) => {
    let icon
    switch (item?.published) {
      case true: icon = <Icon icon="tick-circle" aria-label="Published" intent="success" />
        break;
      case false: icon = item.publishedAt ? <Icon icon="error" aria-label="Unpublished" intent="danger" /> : <></>
        break;
      default: icon = null
    }
    return [false, true].includes(item?.published) ? <Popover2
      interactionKind="hover"
      content={
        <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
          <div style={{ display: "flex", justifyContent: "space-between", gap: 5 }}>
            <div>{item?.published ? "Published" : "Unpublished by Database Manager"}</div>
          </div>
        </div>
      }>
      {icon}
    </Popover2> : icon
  }
  const getConfirmed = (item: any) => {
    var isConfirmed
    if (selectedView?.nationalDatabase) {
      if (item["data.metadata.Confirmed"] === undefined)
        isConfirmed = true // historical data
      else isConfirmed = item["data.metadata.Confirmed"] === "True"
    } else isConfirmed = item.confirmed

    let icon
    switch (isConfirmed) {
      case true: icon = <Icon icon="tick-circle" aria-label="Confirmed" intent="success" />
        break;
      default: icon = null
    }
    return [false, true].includes(isConfirmed) ? <Popover2
      interactionKind="hover"
      content={
        <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
          <div style={{ display: "flex", justifyContent: "space-between", gap: 5 }}>
            <div>{isConfirmed ? "Confirmed" : "Not Confirmed by Database Manager"}</div>
          </div>
        </div>
      }>
      {icon}
    </Popover2> : icon
  }
  const getFileLinks = (item: SampleMetaDataDetailListItem) => {
    let icon
    if (item.inputFileLinks?.length && (item.inputFileLinks as InputFileLink[]).filter(v => v.status === "success").length === item.inputFileLinks.length)
      icon = <Icon icon="saved" aria-label="Files linked" intent="success" />
    if (organization?.sraMetadataField && item[`data.metadata.${organization.sraMetadataField}`])
      icon = <Icon icon="link" aria-label="NCBI SRA Accession ID Linked" intent="primary" />
    else if (item.inputFileLinks && (item.inputFileLinks as InputFileLink[]).filter(v => v.status === "pending").length)
      icon = <Spinner size={15} />
    else if (item.inputFileLinks && (item.inputFileLinks as InputFileLink[]).filter(v => v.status === "error").length)
      icon = <Icon icon="cross-circle" aria-label="Files link errored." intent="danger" />

    if (organization?.sraMetadataField && item[`data.metadata.${organization.sraMetadataField}`])
      return <Popover2
        interactionKind="hover"
        content={
          <div style={{ padding: 5 }}>NCBI SRA Accession ID Linked: {item[`data.metadata.${organization.sraMetadataField}`]}</div>
        }>
        {icon}
      </Popover2>
    else if (item.inputFileLinks?.length)
      return <Popover2
        interactionKind="hover"
        content={
          <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
            {(item.inputFileLinks as InputFileLink[]).map(v => <div style={{ display: "flex", justifyContent: "space-between", gap: 5 }}>
              <div>{v.name}</div>
              {v.status === "success" && <Icon icon="saved" aria-label="File linked" intent="success" />}
              {v.status === "error" && <Icon icon="cross-circle" aria-label="Files link errored." intent="danger" />}
              {v.status === "pending" && <Spinner size={15} />}
            </div>)}
          </div>
        }>
        {icon}
      </Popover2>
    else
      return icon
  }
  const getIcon = (item: any) => {
    let icon
    switch (item.pipelineRunStatus) {
      case "Success": icon = <Icon icon="tick-circle" aria-label="Sample Passed all QC checks." title="Pipeline Successful" intent="success" />
        break;
      case "Failed": icon = <Icon icon="error" aria-label="Please check QC results." intent="danger" />
        break;
      case "Warning": icon = <Icon icon="warning-sign" aria-label="Sample failed one or more QC checks." intent="warning" />
        break;
      case "Cancelled": icon = <Icon icon="disable" aria-label="Pipeline Cancelled" title="Pipeline Cancelled" intent="danger" />
        break;
      case "Error": icon = <Icon icon="cross-circle" aria-label="Pipeline Errored." title="Pipeline Errored" intent="danger" />
        break;
      case "Running": icon = <Spinner size={15} aria-label="Pipeline Running" title="Pipeline Running"></Spinner>
        break;
      default: icon = null
    }
    return ["Failed", "Warning"].includes(item.pipelineRunStatus) ? <Popover2
      interactionKind="hover"
      content={
        <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>

        </div>
      }>
      {icon}
    </Popover2> : icon
  }

  const getLabOBNumber = (item: any) => {
    return item["data.metadata.LabOBNumber"]
  }

  if (!organism)
    throw (new Error("Organism is not defined."))

  const getViewableColumns = useMemo(
    () => selectedView?.columns.filter(col => getViewableFields(!!selectedView?.nationalDatabase)[col.name]) ?? []
    , [selectedView, getViewableFields])

  const colNames = isComparison
    ? ["Checkbox", ...fieldList.map(v => v.name)]
    : selectedView?.nationalDatabase
      ? (organization?.organizationName.toLowerCase().includes("calicinet")
        ? (selectedView?.isOutbreak
          ? ["Checkbox", ...getViewableColumns.map(v => v.name)]
          : ["Checkbox", "Confirmed", "LabOBNumber", ...getViewableColumns.map(v => v.name)])
        : ["Checkbox", ...getViewableColumns.map(v => v.name)])
      : organization?.organizationName.toLowerCase().includes("calicinet")
        ? (selectedView?.isOutbreak
          ? ["Checkbox", "Published", ...getViewableColumns.map(v => v.name)]
          : ["Checkbox", "FileLinks", "_Status", "Published", "Confirmed", "LabOBNumber", ...getViewableColumns.map(v => v.name)])
        : ["Checkbox", "FileLinks", "_Status", "Published", "_NCBIStatus", ...getViewableColumns.map(v => v.name)];
  const colFieldRef = colNames.map(name => {
    if (fieldList.some(i => i.name === 'GroupName') && name === 'GroupName') {
      return {
        name: name,
        required: organism.required.includes(name),
        path: 'data.metadata.GroupName'
      }
    } else {
      return {
        name: name,
        required: organism.required.includes(name),
        ...organism.properties[name]
      }
    }
  }) as FieldRef[];

  const dataMtrx = useMemo(() => {
    let sortedData: SampleMetaDataDetailListItem[] = [];
    if (isSortingBySelected) {
      sortedData = [...data]
      sortedData.sort((a, b) => {
        if (selectedSampleIds.includes(a.id) && !selectedSampleIds.includes(b.id))
          return -1
        else if (selectedSampleIds.includes(a.id) && selectedSampleIds.includes(b.id))
          return 0
        else if (selectedSampleIds.includes(b.id) && !selectedSampleIds.includes(a.id))
          return 1
        else return 0
      })
    }
    let currentData = isSortingBySelected ? sortedData : data

    return currentData.map(dataI => ["data.metadata.Checkbox", ...(isComparison || selectedView?.nationalDatabase ? getStatusFieldsForNDB() : getStatusFields()), ...(isComparison ? fieldList.map(v => v.path) : (getViewableColumns.map(v => v.path) ?? []))].map(path => {
      if (path === "data.metadata.Checkbox") {
        return <input
          alt='checkbox to select/deselect sample'
          type="checkbox"
          checked={isComparison ? selectedItems[dataI.id] : selectedItems[dataI.id] || false}
          onChange={() => handleCheckboxChange(dataI)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleCheckboxChange(dataI)
              e.currentTarget.checked = !e.currentTarget.checked
            }
          }}
        />
      } else if (path === "data.metadata._Status") {
        return <div className='innerRegion-pipeline'><PipelineStatusIcon status={dataI.pipelineRunStatus} sampleId={dataI.id} pipelineRunId={dataI.pipelineRunId}></PipelineStatusIcon></div>
        // getIcon(dataI)
      }
      else if (path === "data.metadata.Published") {
        return <div className='innerRegion-publish'>{getPublished(dataI)}</div>
      }
      else if (path === "data.metadata.Confirmed") {
        return <div className='innerRegion-publish'>{getConfirmed(dataI)}</div>
      }
      else if (path === "inputFileLinks") {
        return <div className='innerRegion-publish'>{getFileLinks(dataI)}</div>
      }
      else if (path === "data.metadata._NCBIStatus") {
        return <div className='innerRegion-pipeline'><PipelineStatusIcon status={dataI["otherPipelineRunStatuses.ncbiPipelineStatus"] ?? ""} sampleId={dataI.id} pipelineRunId={dataI.ncbiPipelineRunId} ncbiPipeline></PipelineStatusIcon></div>
      }
      else if (path === "data.metadata.LabOBNumber") {
        return <div className='innerRegion-publish'>{getLabOBNumber(dataI)}</div>
      }
      else {
        return (dataI[path] ? dataI[path] : "")
      }
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedSampleIds, isSortingBySelected]);

  const [state, setState] = useState<EditDataViewerState>({
    sortedIndexMap: [],
    loadingCells: []
  })

  // unselect items right after setUnselectafterdelete has been called
  useEffect(() => {
    if (activeUnselectafterdelete) {
      setSelectedSampleIds([])
      if (!isComparison)
        setFocusedSampleId(undefined)
      // Reset the checked status of all items
      const resetSelectedItems: Record<string, boolean> = {};
      data.forEach(item => {
        resetSelectedItems[item.id] = false;
      });
      setSelectedItems(resetSelectedItems);

      // Also reset the 'Select All' checkbox
      setSelectAll(false);
      if (!isComparison)
        setUnselectafterdelete(false);
      else
        setUnselectafterdeleteComparison(false);

      //refresh the page
      // if (!isComparison && selectedViewId)
      //   loadView(selectedViewId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUnselectafterdelete])

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const dataKey = `${columnIndex}-${rowIndex}`

    if (activeCellEdits[dataKey] && activeCellEdits[dataKey].valid !== false) {
      return activeCellEdits[dataKey].value;
    }
    else if (dataMtrx[rowIndex] && dataMtrx[rowIndex][columnIndex] !== undefined) {
      return dataMtrx[rowIndex][columnIndex];
    }
    else {
      return "";
    }
  };

  const setCellData = async (value: string, rowIndex: number, columnIndex: number, field: FieldRef) => {
    const dataKey = `${columnIndex}-${rowIndex}`
    let newCellEdits = cloneDeep(activeCellEdits)

    if (dataMtrx[rowIndex][columnIndex] !== value) {
      let error = field.allowUnknown
        ? value?.trim().toLowerCase() !== "unknown" && field.pattern && !(new RegExp(field.pattern).test(value)) ? `Invalid format. (eg: unknown, ${field.examples?.join(', ')})` : undefined
        : field.pattern && !(new RegExp(field.pattern).test(value)) ? `Invalid format. (eg: ${field.examples?.join(', ')})` : undefined
      error = !value && field.required ? "This field is required." : error

      let nullValueNeededForEmptyDate = false
      if(value === "" && field.type === "date"){
        error = undefined
        nullValueNeededForEmptyDate = true
      }

      newCellEdits[dataKey] = {
        value: !nullValueNeededForEmptyDate ? value : null,
        path: ["data.metadata.Checkbox", ...(isComparison || selectedView?.nationalDatabase ? getStatusFieldsForNDB() : getStatusFields()), ...(isComparison ? fieldList.map(v => v.path) : (getViewableColumns.map(v => v.path) ?? []))][columnIndex],
        rowIndex,
        error
      }
    } else
      delete newCellEdits[dataKey]

    let oldCellEdits = cloneDeep(activeCellEdits);
    var newItem = { type: 'change', cellEdits: oldCellEdits, newData: newCellEdits };
    editHistory.push(newItem);

    // For performance reasons and to avoid an infinitely growing array, only store the last 20 changes
    while (editHistory.length > 20) {
      editHistory.shift();
    }
    //reset stored data for "ctrl+y"
    redoUndone_editHistory = [];

    !isComparison ? setCellEdits(newCellEdits) : setCellEditsComparison(newCellEdits)
    if (copiedCells) {
      resetBorders(copiedCells.leftmostIndex, copiedCells.rightmostIndex, copiedCells.topmostIndex, copiedCells.bottommostIndex, activeIndex);
    }
  }

  const sortColumn = (columnIndex: number, comparator: (a: any, b: any) => number, sortedColumnAscending: boolean) => {
    return;
  };

  const numRows = dataMtrx.length
  const [istriggered, setIstriggered] = useState(0);

  const addDomEvents = () => {
    const handler = setTimeout(() => {
      setIstriggered(istriggered + 1)
    }, 0)
    return () => clearTimeout(handler);
  }

  // the width of editDataTable from comparisonView is shorter
  const editDataTable = document.getElementsByClassName('editDataTable')
  useEffect(() => {
    let cleanupFunction;
    if (editDataTable.length > 1)
      if (editDataTable[0].clientWidth > editDataTable[1].clientWidth) {
        if (isComparison) {
          editDataTableElement = editDataTable[1] as HTMLElement
          activeIndex = 1
        } else {
          editDataTableElement = editDataTable[0] as HTMLElement
          activeIndex = 0
        }
        if (previousNumberOfEditDataViewerTable !== editDataTable.length) {
          setPreviousNumberOfEditDataViewerTable(editDataTable.length)
          cleanupFunction = addDomEvents()
        }
      }
      else {
        if (isComparison) {
          editDataTableElement = editDataTable[0] as HTMLElement
          activeIndex = 0
        } else {
          editDataTableElement = editDataTable[1] as HTMLElement
          activeIndex = 1
        }
        if (previousNumberOfEditDataViewerTable !== editDataTable.length) {
          setPreviousNumberOfEditDataViewerTable(editDataTable.length)
          cleanupFunction = addDomEvents()
        }
      }
    else if (editDataTable.length === 1) {
      editDataTableElement = editDataTable[0] as HTMLElement
      activeIndex = 0
      if (previousNumberOfEditDataViewerTable !== editDataTable.length) {
        setPreviousNumberOfEditDataViewerTable(editDataTable.length)
        cleanupFunction = addDomEvents()
      }
    }
    return cleanupFunction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDataTable.length])

  const columns = useMemo(() => {
    if (queryIsLoading && !fullComparison) {
      return
    }
    if (activeNewEdits.length) {
      const editedColumns = activeNewEdits.map(item => parseInt(item.split('-')[0], 10));

      const updatedColumns: any = colNames.map((colName, index) => {
        let descrip = colFieldRef[index].description || ''
        const path = colFieldRef[index].path || ""
        let title = colFieldRef[index].title || colName
        let access = colFieldRef[index].access || "internal"
        if (editedColumns.includes(index)) {
          let _canEditField = canEdit && canEditField(access, !!selectedView?.nationalDatabase)
          return new SortableColumn(
            colName,
            index,
            data,
            updateSelections,
            selectSample,
            selectAll,
            handleSelectAllChange,
            selectedItems,
            handleCheckboxChange,
            getIcon,
            getPublished,
            _canEditField,
            descrip,
            selectedView,
            handleShowOutbreakForm,
            isComparison && currentTab ? currentTab.sort : sort,
            isComparison && currentTab ? setSortForComparison : setSort,
            isComparison && currentTab ? setPreviousDataForComparison : setPreviousData,
            path,
            title,
            columnFilters,
            setColumnFilters,
            organization?.ndbIndexedFields ?? [],
            isComparison,
            currentTab?.viewDisplayed ?? "",
            currentTab?.isSortedInDendrogram,
            setIsSortedInDendrogram).getColumn(getCellData, setCellData, sortColumn, state, activeCellEdits, colFieldRef, activeShowSingleClickEdit.current, activeIndex, groups);
        } else {
          return tempColumns[index];
        }
      });

      return updatedColumns;
    } else {
      return colNames.map((colName, index) => {
        let descrip = colFieldRef[index].description || ''
        const path = colFieldRef[index].path || ""
        let title = colFieldRef[index].title || colName
        let access = colFieldRef[index].access || "internal"
        let _canEditField = canEdit && canEditField(access, !!selectedView?.nationalDatabase)
        return new SortableColumn(colName, index, data, updateSelections, selectSample, selectAll, handleSelectAllChange, selectedItems, handleCheckboxChange, getIcon, getPublished, _canEditField, descrip, selectedView, handleShowOutbreakForm, isComparison && currentTab ? currentTab.sort : sort, isComparison && currentTab ? setSortForComparison : setSort, isComparison && currentTab ? setPreviousDataForComparison : setPreviousData, path, title, columnFilters, setColumnFilters, organization?.ndbIndexedFields ?? [], isComparison, currentTab?.viewDisplayed ?? "", currentTab?.isSortedInDendrogram, setIsSortedInDendrogram).getColumn(getCellData, setCellData, sortColumn, state, activeCellEdits, colFieldRef, activeShowSingleClickEdit.current, activeIndex, groups)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, state, selectedSampleIds, queryIsLoading, selectAll, isSortingBySelected, activeCellEdits]);

  useEffect(() => {
    setTempColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns])

  const prevCellEditsRef = useRef(activeCellEdits);

  useEffect(() => {
    if (activeNewEdits.length) {
      const newState = {
        ...state,
        loadingCells: activeNewEdits
      };
      setState(newState);
    } else {
      const newState = {
        ...state,
        loadingCells: []
      };
      setState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNewEdits])

  useEffect(() => {
    const prevCellEdits = prevCellEditsRef.current;
    const currentCellEdits = activeCellEdits;

    !isComparison ? setNewEdits(Object.keys(currentCellEdits).filter(key => !prevCellEdits[key])) : setNewEditsComparison(Object.keys(currentCellEdits).filter(key => !prevCellEdits[key]));

    // Update the previous cell edits snapshot for the next comparison
    prevCellEditsRef.current = activeCellEdits;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCellEdits]);

  useEffect(() => {
    setShowSingleclickedit(activeShowSingleClickEdit.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeShowSingleClickEdit]);

  useEffect(() => {
    setIsComparison(isComparison)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComparison]);
  const activeElement = document.querySelector(":focus")
  useEffect(() => {
    if (columns?.length && (istriggered || editDataTable.length === previousNumberOfEditDataViewerTable) && editDataTableElement) {
      let cell = editDataTableElement as HTMLElement;
      if (cell) {
        const handleKeyUp = (event: KeyboardEvent) => {
          if (event.key === 'Shift') {
            isShiftPressedRef.current = false;
          }
          else if (event.key === "Alt") {
            isAltPressed.current = false
          }
        };
        const handleKeyDown = async (event: KeyboardEvent) => {
          if (event.key === "Alt") {
            isAltPressed.current = true
          }
          if (event.key === 'Shift') {
            isShiftPressedRef.current = true;
          }
          if (event.ctrlKey && event.key === "a") {
            event.preventDefault()
            setSelectedSampleIds(data.map((sample) => sample.id))
            setSelectAll(true)
          }
          if (event.key === "Escape") {
            if (!isComparison) {
              document.getElementById("home-button")?.focus()
            }
            else {
              event.preventDefault()
              event.stopPropagation()
              document.getElementById(`tab-${selectedTabId}`)?.focus()
            }
          }
          if (event.key === "F4") {
            event.preventDefault()
            setSelectAll(false)
            setSelectedSampleIds([])
          }
          if (isAltPressed && event.key === "t") {
            setIsSortingBySelected(!isSortingBySelected)
          }

          if (activeShowSingleClickEdit.current) {
            if (isKeyPressHandledRef.current) return;
            isKeyPressHandledRef.current = true;
            setTimeout(() => {
              isKeyPressHandledRef.current = false;
            }, 0);

            if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key) && !event.shiftKey) {
              event.preventDefault()
              if (tableRef.current?.state.selectedRegions) {
                while (tableRef.current.state.selectedRegions.length > 1) {
                  tableRef.current.state.selectedRegions.pop();
                }
                let row = 0, col = 0;
                if (event.key === "ArrowUp") {
                  row = (tableRef.current.state.selectedRegions[0].rows?.[1] ?? 0) - 1
                  col = tableRef.current.state.selectedRegions[0].cols?.[1] ?? 0
                } else if (event.key === "ArrowDown") {
                  row = (tableRef.current.state.selectedRegions[0].rows?.[1] ?? 0) + 1
                  col = tableRef.current.state.selectedRegions[0].cols?.[1] ?? 0
                } else if (event.key === "ArrowLeft") {
                  row = tableRef.current.state.selectedRegions[0].rows?.[1] ?? 0
                  col = (tableRef.current.state.selectedRegions[0].cols?.[1] ?? 0) - 1
                } else if (event.key === "ArrowRight") {
                  row = tableRef.current.state.selectedRegions[0].rows?.[1] ?? 0
                  col = (tableRef.current.state.selectedRegions[0].cols?.[1] ?? 0) + 1
                }
                handleCellFocus(row, col)
              }
            }

            if (event.key === 'Tab' && !event.shiftKey) {
              event.preventDefault()
              if (tableRef.current?.state.selectedRegions) {
                let row = 0, col = 0;
                row = tableRef.current.state.selectedRegions[0].rows?.[0] ?? 0
                col = tableRef.current.state.selectedRegions[0].cols?.[0] ?? 0

                // Check if it's the last column in the row
                if (tableRef.current?.grid?.numCols) {
                  if (col > tableRef.current.grid.numCols - 1) {
                    row += 1;
                    // Check if it's the last row
                    if (tableRef.current?.grid?.numRows) {
                      if (row > tableRef.current.grid.numRows - 1) {
                        return;
                      }
                    }
                    col = 0;
                  } else {
                    col += 1;
                  }
                }

                if (tableRef.current) {
                  while (tableRef.current.state.selectedRegions.length > 0) {
                    tableRef.current.state.selectedRegions.pop();
                  }
                  tableRef.current?.state.selectedRegions.push({
                    cols: [col, col],
                    rows: [row, row]
                  })
                }

                //view goes all the way to the left when users tab from the Key field(4th column)
                if (col === 3) {
                  let scroll_element = getNestedChild(editDataTableElement, 0, 0, 0) as HTMLElement;
                  if (scroll_element) {
                    scroll_element.scrollLeft = 0;
                  }
                }

                handleCellFocus(row, col)
              }
            }

            if ((event.ctrlKey || event.metaKey) && (event.key.toLowerCase() === 'c' || event.key.toLowerCase() === 'v' || event.key.toLowerCase() === "x" || event.key.toLowerCase() === 'y' || event.key.toLowerCase() === 'z') && !event.shiftKey) {
              if (event.target instanceof HTMLElement) {
                const targetElement = event.target.parentElement?.parentElement?.parentElement?.parentElement
                const targetClass = targetElement?.getAttribute('class');

                const rowIndexMatch = targetClass?.match(/cell-row-(\d+)/);
                const columnIndexMatch = targetClass?.match(/cell-col-(\d+)/);

                const rowIndex = rowIndexMatch ? parseInt(rowIndexMatch[1], 10) : null;
                const columnIndex = columnIndexMatch ? parseInt(columnIndexMatch[1], 10) : null;

                if (event.key.toLowerCase() === 'v' && rowIndex !== null && columnIndex !== null) {
                  currentCellIndicesRef.current = { rowIndex, columnIndex };
                  setFocusedCell({ rowIndex, columnIndex });
                  if (hiddenInputRef.current) {
                    currentCellIndicesRef.current = { rowIndex, columnIndex };
                    setFocusedCell({ rowIndex, columnIndex });
                    hiddenInputRef.current.focus();
                  }
                } else {
                  await handleKeyboardActions_Copy_Cut_Paste_Redo_Undo(event)
                }
              }
            }
          }

          //In single-click edit mode, keyboard control features are not supported.
          if (activeShowSingleClickEdit.current) return;

          if ((event.ctrlKey || event.metaKey) && (event.key.toLowerCase() === 'c' || event.key.toLowerCase() === 'v' || event.key.toLowerCase() === 'x' || event.key.toLowerCase() === "z" || event.key.toLowerCase() === 'y')) {
            await handleKeyboardActions_Copy_Cut_Paste_Redo_Undo(event)
          } else if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key) && !event.shiftKey) {
            event.preventDefault()
          } else if (event.key === 'Tab' && !event.shiftKey && activeElement && activeElement.className.includes("bp5-table-cell")) {
            event.preventDefault();
            let rowIndex, columnIndex;

            if (tableRef.current) {
              if (tableRef.current.state.selectedRegions[0]) {
                rowIndex = (tableRef.current.state.selectedRegions[0]?.rows?.[0] !== undefined && tableRef.current.state.selectedRegions[0]?.rows?.[0] !== null)
                  ? tableRef.current.state.selectedRegions[0].rows?.[0]
                  : null;
              } else {
                rowIndex = 0
              }
              if (tableRef.current.state.selectedRegions[0]) {
                columnIndex = (tableRef.current.state.selectedRegions[0]?.cols?.[0] !== undefined && tableRef.current.state.selectedRegions[0]?.cols?.[0] !== null)
                  ? tableRef.current.state.selectedRegions[0].cols?.[0]
                  : null;
              } else {
                columnIndex = 0
              }
            }

            if (rowIndex === undefined) {
              rowIndex = null;
            }
            if (columnIndex === undefined) {
              columnIndex = null;
            }

            if (rowIndex !== null && columnIndex !== null) {
              let newRowIndex = rowIndex;
              let newColumnIndex = columnIndex;

              newColumnIndex += 1;

              // Check if it's the last column in the row
              if (tableRef.current?.grid?.numCols) {
                if (newColumnIndex > tableRef.current.grid.numCols - 1) {
                  newRowIndex += 1;
                  // Check if it's the last row
                  if (tableRef.current?.grid?.numRows) {
                    if (newRowIndex > tableRef.current.grid.numRows - 1) {
                      return;
                    }
                  }
                  newColumnIndex = 0;
                }
              }

              if (tableRef.current) {
                while (tableRef.current.state.selectedRegions.length > 0) {
                  tableRef.current.state.selectedRegions.pop();
                }
                tableRef.current?.state.selectedRegions.push({
                  cols: [newColumnIndex, newColumnIndex],
                  rows: [newRowIndex, newRowIndex]
                })
              }

              //view goes all the way to the left when users tab from the Key field(4th column)
              if (columnIndex === 3) {
                let scroll_element = getNestedChild(editDataTableElement, 0, 0, 0) as HTMLElement;
                if (scroll_element) {
                  scroll_element.scrollLeft = 0;
                }
              }

              //force update to update focused cells..
              const targetClass = getNestedChild(editDataTableElement, 0, 4, 0, 1, 1, 0, 0, 0, 0)?.getAttribute('class') ?? getNestedChild(editDataTableElement, 0, 4, 0, 1, 0, 0, 0, 0, 0)?.getAttribute('class');
              const targetClassSplited = targetClass?.replace(new RegExp(`(.*?-cell-row-)[0-9]+`), `$1${newRowIndex}`)
                .replace(new RegExp(`(.*?-cell-col-)[0-9]+`), `$1${newColumnIndex}`)
                .split(' ');

              // checks for a dash followed by one or more digits at the end
              const regexEndsWithNumber = /-\d+$/;
              let nextTargetClass = targetClassSplited?.filter((className: string) => regexEndsWithNumber.test(className)).join(' ');

              if (nextTargetClass) {
                const nextCell = document.getElementsByClassName(nextTargetClass)[activeIndex === 0 ? activeIndex : document.getElementsByClassName(nextTargetClass)?.length - 1];
                if (nextCell instanceof HTMLElement) {
                  nextCell.focus();
                }
              }
              forceUpdate(Math.random());
            }
          }
        }

        const handleClick = (event: MouseEvent) => {
          //In single-click edit mode, keyboard control features are not supported.
          if (activeShowSingleClickEdit.current) return;

          if (event.target instanceof HTMLElement) {
            const targetElement = event.target.parentElement?.parentElement
            const targetClass = targetElement?.getAttribute('class');

            const rowIndexMatch = targetClass?.match(/cell-row-(\d+)/);
            const columnIndexMatch = targetClass?.match(/cell-col-(\d+)/);

            const rowIndex = rowIndexMatch ? parseInt(rowIndexMatch[1], 10) : null;
            const columnIndex = columnIndexMatch ? parseInt(columnIndexMatch[1], 10) : null;
            if (event.shiftKey) {

              if (hiddenMultiInputRef.current && rowIndex !== null && columnIndex !== null) {

                let tempCells = focusedCellsRef.current?.slice() || [];
                let rowStart = Infinity, rowEnd = -Infinity, colStart = Infinity, colEnd = -Infinity

                // Include the newly clicked cell in the calculation by initially setting it as the min/max
                rowStart = Math.min(rowStart, rowIndex)
                rowEnd = Math.max(rowEnd, rowIndex)
                colStart = Math.min(colStart, columnIndex)
                colEnd = Math.max(colEnd, columnIndex)

                // Iterate through already selected cells to update the boundaries
                tempCells?.forEach((tempCell) => {
                  const rowIndex = tempCell.rows[1]
                  const colIndex = tempCell.cols[1]

                  rowStart = Math.min(rowStart, rowIndex)
                  rowEnd = Math.max(rowEnd, rowIndex)
                  colStart = Math.min(colStart, colIndex)
                  colEnd = Math.max(colEnd, colIndex)
                })

                // Clear the current selection
                tempCells = []
                // Push cells within the boundaries into tempCells
                tempCells.push({
                  rows: [rowStart, rowEnd],
                  cols: [colStart, colEnd]
                })

                setFocusedCell({ rowIndex, columnIndex });
                while (focusedCellsRef.current && focusedCellsRef.current.length > 0) {
                  focusedCellsRef.current.pop();
                }
                tempCells?.forEach((tempCell) => {
                  focusedCellsRef.current?.push(tempCell);
                });
                if (tableRef.current) {
                  while (tableRef.current.state.selectedRegions.length > 0) {
                    tableRef.current.state.selectedRegions.pop();
                  }
                  tempCells?.forEach((tempCell) => {
                    tableRef.current?.state.selectedRegions.push(tempCell)
                  });
                  //force update to update focused cells..
                  forceUpdate(Math.random());
                }
              }
            } else {
              if (rowIndex !== null && columnIndex !== null) {
                while (focusedCellsRef.current && focusedCellsRef.current.length > 0) {
                  focusedCellsRef.current.pop();
                }
                focusedCellsRef.current?.push({
                  cols: [columnIndex, columnIndex],
                  rows: [rowIndex, rowIndex]
                });
              }
            }
          }
        }

        const handleKeyboardActions_Copy_Cut_Paste_Redo_Undo = async (event: KeyboardEvent) => {
          if ((event.ctrlKey || event.metaKey) && (event.key.toLowerCase() === 'v' || event.key.toLowerCase() === "x")) {
            if (event.target instanceof HTMLElement) {
              if (tableRef.current?.state?.selectedRegions && (tableRef.current.state.selectedRegions.length > 1 ||
                (
                  (tableRef.current?.state?.selectedRegions[0]?.cols?.[0] !== tableRef.current?.state?.selectedRegions[0]?.cols?.[1]) ||
                  (tableRef.current?.state?.selectedRegions[0]?.rows?.[0] !== tableRef.current?.state?.selectedRegions[0]?.rows?.[1])
                )
              )) {
                let rowIndex, columnIndex;
                if (tableRef.current) {
                  rowIndex = (tableRef.current.state.selectedRegions[0]?.rows?.[0] !== undefined && tableRef.current.state.selectedRegions[0]?.rows?.[0] !== null)
                    ? tableRef.current.state.selectedRegions[0].rows?.[0]
                    : null;

                  columnIndex = (tableRef.current.state.selectedRegions[0]?.cols?.[0] !== undefined && tableRef.current.state.selectedRegions[0]?.cols?.[0] !== null)
                    ? tableRef.current.state.selectedRegions[0].cols?.[0]
                    : null;
                }

                if (rowIndex === undefined) {
                  rowIndex = null;
                }
                if (columnIndex === undefined) {
                  columnIndex = null;
                }
                setFocusedCell({ rowIndex, columnIndex });
                if (event.key.toLowerCase() === "v") {
                  hiddenMultiInputRef.current?.focus();
                } else {
                  await handleMultiPasteEvent(undefined, true)
                }
              } else {
                let rowIndex, columnIndex;
                if (tableRef.current) {
                  rowIndex = (tableRef.current.state.selectedRegions[0]?.rows?.[0] !== undefined && tableRef.current.state.selectedRegions[0]?.rows?.[0] !== null)
                    ? tableRef.current.state.selectedRegions[0].rows?.[0]
                    : null;

                  columnIndex = (tableRef.current.state.selectedRegions[0]?.cols?.[0] !== undefined && tableRef.current.state.selectedRegions[0]?.cols?.[0] !== null)
                    ? tableRef.current.state.selectedRegions[0].cols?.[0]
                    : null;
                }

                if (rowIndex === undefined) {
                  rowIndex = null;
                }
                if (columnIndex === undefined) {
                  columnIndex = null;
                }

                if (hiddenInputRef.current && rowIndex !== null && columnIndex !== null) {
                  currentCellIndicesRef.current = { rowIndex, columnIndex };
                  setFocusedCell({ rowIndex, columnIndex });
                  if (event.key.toLowerCase() === "v") {
                    hiddenInputRef.current.focus();
                  } else {
                    await handlePasteEvent(undefined, true)
                  }
                }
              }
            }
          } else if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'z') {
            const lastHistory = editHistory.pop();
            if (lastHistory) {
              if (copiedCells) {
                resetBorders(copiedCells.leftmostIndex, copiedCells.rightmostIndex, copiedCells.topmostIndex, copiedCells.bottommostIndex, activeIndex);
              }
              !isComparison ? setCellEdits(lastHistory.cellEdits) : setCellEditsComparison(lastHistory.cellEdits);
              redoUndone_editHistory.push(lastHistory);

              // For performance reasons and to avoid an infinitely growing array, only store the last 20 changes
              while (redoUndone_editHistory.length > 20) {
                redoUndone_editHistory.shift();
              }

              forceUpdate(Math.random());
            }
          } else if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'y') {
            const lastHistory = redoUndone_editHistory.pop();
            if (lastHistory) {
              !isComparison ? setCellEdits(lastHistory.newData) : setCellEditsComparison(lastHistory.newData);
              editHistory.push(lastHistory);

              // For performance reasons and to avoid an infinitely growing array, only store the last 20 changes
              while (editHistory.length > 20) {
                editHistory.shift();
              }

              forceUpdate(Math.random());
            }
          } else if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'c') {
            if (tableRef.current) {
              tableRef.current?.state.selectedRegions.forEach((selectedRegion) => {
                if (selectedRegion.cols && selectedRegion.rows) {
                  let leftmostIndex = Infinity;
                  let rightmostIndex = -Infinity;
                  let topmostIndex = Infinity;
                  let bottommostIndex = -Infinity;

                  for (let i: number = selectedRegion.cols[0]; i <= selectedRegion.cols[1]; i++) {
                    for (let j: number = selectedRegion.rows[0]; j <= selectedRegion.rows[1]; j++) {
                      leftmostIndex = Math.min(leftmostIndex, i);
                      rightmostIndex = Math.max(rightmostIndex, i);
                      topmostIndex = Math.min(topmostIndex, j);
                      bottommostIndex = Math.max(bottommostIndex, j);
                    }
                  }

                  drawBorders(leftmostIndex, rightmostIndex, topmostIndex, bottommostIndex);
                  copiedCells = {
                    leftmostIndex,
                    rightmostIndex,
                    topmostIndex,
                    bottommostIndex
                  };
                }
              })
            }
          }

        }

        cell.addEventListener('keydown', handleKeyDown, { capture: true });
        cell.addEventListener('mousedown', handleClick);
        cell.addEventListener('keyup', handleKeyUp);
        return () => {
          cell.removeEventListener('keydown', handleKeyDown, { capture: true });
          cell.removeEventListener('mousedown', handleClick);
          cell.addEventListener('keyup', handleKeyUp);
        };
      }
    }

    //To resolve the issue where the foucs in the editDataviewer is occasionally lost
    const table = tableWrapperRef.current;
    if (table) {
      const tableScrollable = table.querySelector('[class$="scroll-container"]')

      if (tableScrollable) {
        const handleScroll = () => {
          editDataTableElement?.focus()
        }
        tableScrollable.addEventListener('scroll', handleScroll)

        return () => {
          table.removeEventListener('scroll', handleScroll)
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, istriggered, editDataTableElement]);

  const handleCellFocus = (
    row: number,
    col: number
  ) => {
    const targetClass = getNestedChild(editDataTableElement, 0, 4, 0, 1, 1, 0, 0, 0, 0)?.getAttribute('class') ?? getNestedChild(editDataTableElement, 0, 4, 0, 1, 0, 0, 0, 0, 0)?.getAttribute('class');
    const targetClassSplited = targetClass?.replace(new RegExp(`(.*?-cell-row-)[0-9]+`), `$1${row}`)
      .replace(new RegExp(`(.*?-cell-col-)[0-9]+`), `$1${col}`)
      .split(' ');

    const regexEndsWithNumber = /-\d+$/;
    let targetClass2 = targetClassSplited?.filter((className: string) => regexEndsWithNumber.test(className)).join(' ');

    if (targetClass2) {
      const cell = document.getElementsByClassName(targetClass2)[activeIndex === 0 ? activeIndex : document.getElementsByClassName(targetClass2)?.length - 1] as HTMLElement
      if (cell) {
        if (tableRef.current) {
          while (focusedCellsRef.current && focusedCellsRef.current.length > 0) {
            focusedCellsRef.current.pop()
          }
          focusedCellsRef.current?.push({
            cols: [col, col],
            rows: [row, row]
          });
          while (tableRef.current.state.selectedRegions.length > 0) {
            tableRef.current.state.selectedRegions.pop();
          }
          tableRef.current?.state.selectedRegions.push({
            cols: [col, col],
            rows: [row, row]
          })
        }
        const inputElement = getNestedChild(cell, 0, 0, 1) as HTMLInputElement
        const selectElement = getNestedChild(cell, 0, 0, 0, 0) as HTMLSelectElement
        if (inputElement !== null) {
          inputElement.click()
          forceUpdate(Math.random())
        } else if (selectElement !== null) {
          selectElement.focus()
          forceUpdate(Math.random())
        }
      }
    }
  }

  const handlePasteEvent = async (event?: React.ClipboardEvent<HTMLInputElement>, deleteCopied?: boolean) => {
    const targetClass = getNestedChild(editDataTableElement, 0, 4, 0, 1, 1, 0, 0, 0, 0)?.getAttribute('class') ?? getNestedChild(editDataTableElement, 0, 4, 0, 1, 0, 0, 0, 0, 0)?.getAttribute('class');
    const targetClassSplited = targetClass?.replace(new RegExp(`(.*?-cell-row-)[0-9]+`), `$1${focusedCell.rowIndex}`)
      .replace(new RegExp(`(.*?-cell-col-)[0-9]+`), `$1${focusedCell.columnIndex}`)
      .split(' ');

    // checks for a dash followed by one or more digits at the end
    const regexEndsWithNumber = /-\d+$/;
    let targetClass2 = targetClassSplited?.filter((className: string) => regexEndsWithNumber.test(className)).join(' ');

    if (targetClass2) {
      const cell = document.getElementsByClassName(targetClass2)[activeIndex === 0 ? activeIndex : document.getElementsByClassName(targetClass2)?.length - 1] as HTMLElement;
      cell.focus();
    }

    const clipboardData = event?.clipboardData;
    let pastedDataRaw: string;
    if (!clipboardData) {
      pastedDataRaw = await navigator.clipboard.readText()
    } else {
      pastedDataRaw = clipboardData.getData("Text")
    }

    // Split by rows and then by columns
    const pastedRows = pastedDataRaw.split('\r\n').filter(row => row.length > 0);
    const pastedCells = pastedRows.map(row => row.split('\t'));

    const { rowIndex, columnIndex } = currentCellIndicesRef.current;

    if (rowIndex !== null && columnIndex !== null) {
      let newCellEdits = cloneDeep(activeCellEdits);

      let leftmostIndex = Infinity;
      let rightmostIndex = -Infinity;
      let topmostIndex = Infinity;
      let bottommostIndex = -Infinity;

      for (let i = 0; i < pastedCells.length; i++) {
        for (let j = 0; j < pastedCells[i].length; j++) {
          const currentRow = rowIndex + i;
          const currentColumn = columnIndex + j;

          //user cannot edit data in 'GroupName' column
          if (columns.map((column: any) => column.props?.name)[currentColumn] === 'GroupName') continue;

          // Check if the current row or column exceeds the grid's boundaries. If so, skip to the next iteration.
          if (tableRef.current?.grid?.numRows &&
            tableRef.current?.grid?.numCols &&
            (currentRow > tableRef.current?.grid?.numRows - 1 || currentColumn > tableRef.current.grid.numCols - 1)
          ) { continue; }

          leftmostIndex = Math.min(leftmostIndex, currentColumn);
          rightmostIndex = Math.max(rightmostIndex, currentColumn);
          topmostIndex = Math.min(topmostIndex, currentRow);
          bottommostIndex = Math.max(bottommostIndex, currentRow);

          const dataKey = `${currentColumn}-${currentRow}`;

          let field = colFieldRef[currentColumn]
          let error = field.pattern && !(new RegExp(field.pattern).test(pastedCells[i][j])) ? `Invalid format. (eg: ${field.examples?.join(', ')})` : undefined
          error = !pastedCells[i][j] && field.required ? "This field is required." : error

          // Check if the cell can be edited. 
          const access = colFieldRef[currentColumn].access || "internal"
          const isPublishedOrConfirmed = ((data[currentRow]?.organization?.toLowerCase().includes("calicinet") && data[currentRow]?.confirmed ) ||  
                               (!data[currentRow]?.organization?.toLowerCase().includes("calicinet") && data[currentRow]?.published))&& 
                               field.access !== "lab_only_field" // disable editing cells for published row for PN and for confirmed row for CN
          let _canEditField = canEdit && canEditField(access, !!selectedView?.nationalDatabase) && !isPublishedOrConfirmed
          if (!_canEditField) continue;

          newCellEdits[dataKey] = {
            value: pastedCells[i][j],
            path: ["data.metadata.Checkbox", ...(isComparison || selectedView?.nationalDatabase ? getStatusFieldsForNDB() : getStatusFields()), ...(isComparison ? fieldList.map(v => v.path) : (getViewableColumns.map(v => v.path) ?? []))][currentColumn],
            rowIndex: currentRow,
            error
          }
        }
      }

      if (copiedCells && deleteCopied) {
        for (let i = copiedCells.leftmostIndex; i <= copiedCells.rightmostIndex; i++) {
          for (let j = copiedCells.topmostIndex; j <= copiedCells.bottommostIndex; j++) {
            newCellEdits[`${i}-${j}`] = {
              value: "",
              path: ["data.metadata.Checkbox", ...(isComparison || selectedView?.nationalDatabase ? [] : ["data.metadata._Status", "data.metadata.Published"]), ...(isComparison ? fieldList.map(v => v.path) : (getViewableColumns.map(v => v.path) ?? []))][i],
              rowIndex: i,
            }
          }
        }
      }



      let oldCellEdits = cloneDeep(activeCellEdits);
      var newItem = { type: 'paste', cellEdits: oldCellEdits, newData: newCellEdits };
      editHistory.push(newItem);

      // For performance reasons and to avoid an infinitely growing array, only store the last 20 changes
      while (editHistory.length > 20) {
        editHistory.shift();
      }
      //reset stored data for "ctrl+y"
      redoUndone_editHistory = [];

      !isComparison ? setCellEdits(newCellEdits) : setCellEditsComparison(newCellEdits)
      if (copiedCells) {
        resetBorders(copiedCells.leftmostIndex, copiedCells.rightmostIndex, copiedCells.topmostIndex, copiedCells.bottommostIndex, activeIndex);
      }
    }

    if (currentCellIndicesRef.current) {
      currentCellIndicesRef.current.rowIndex = null;
      currentCellIndicesRef.current.columnIndex = null;
    }
  };

  const handleMultiPasteEvent = async (event?: React.ClipboardEvent<HTMLInputElement>, deleteCopied?: boolean) => {
    //focusedCell?.focus();
    const targetClass = getNestedChild(editDataTableElement, 0, 4, 0, 1, 1, 0, 0, 0, 0)?.getAttribute('class') ?? getNestedChild(editDataTableElement, 0, 4, 0, 1, 0, 0, 0, 0, 0)?.getAttribute('class');
    const targetClassSplited = targetClass?.replace(new RegExp(`(.*?-cell-row-)[0-9]+`), `$1${focusedCell.rowIndex}`)
      .replace(new RegExp(`(.*?-cell-col-)[0-9]+`), `$1${focusedCell.columnIndex}`)
      .split(' ');

    // checks for a dash followed by one or more digits at the end
    const regexEndsWithNumber = /-\d+$/;
    let targetClass2 = targetClassSplited?.filter((className: string) => regexEndsWithNumber.test(className)).join(' ');

    if (targetClass2) {
      const cell = document.getElementsByClassName(targetClass2)[activeIndex === 0 ? activeIndex : document.getElementsByClassName(targetClass2)?.length - 1] as HTMLElement;
      cell.focus();
    }

    const clipboardData = event?.clipboardData;
    let pastedDataRaw: string;
    if (!clipboardData) {
      pastedDataRaw = await navigator.clipboard.readText()
    } else {
      pastedDataRaw = clipboardData.getData("Text")
    }


    // Split by rows and then by columns
    const pastedRows = pastedDataRaw.split('\r\n').filter(row => row.length > 0);
    const pastedCells = pastedRows.map(row => row.split('\t'));

    let newCellEdits = cloneDeep(activeCellEdits);

    if (tableRef.current) {
      tableRef.current?.state.selectedRegions
        .filter((selectedRegion: Region) => {
          if (!selectedRegion.cols || !selectedRegion.rows) return false;
          //user cannot edit data in 'GroupName' column
          const columnName = columns.map((column: any) => column.props?.name)[selectedRegion.cols[1]];
          return columnName !== 'GroupName';
        })
        .forEach((selectedRegion) => {
          if (selectedRegion.cols && selectedRegion.rows) {
            if (tableRef.current?.state.selectedRegions && tableRef.current?.state.selectedRegions.length > 1) {
              const dataKey = `${selectedRegion.cols[1]}-${selectedRegion.rows[1]}`;

              let field = colFieldRef[selectedRegion.cols[1]]
              let error = field.pattern && !(new RegExp(field.pattern).test(pastedCells[0][0])) ? `Invalid format. (eg: ${field.examples?.join(', ')})` : undefined
              error = !pastedCells[0][0] && field.required ? "This field is required." : error

              // Check if the cell can be edited. 
              const access = colFieldRef[selectedRegion.cols[1]].access || "internal"
              const isPublishedOrConfirmed = ((data[selectedRegion.rows[1]]?.organization?.toLowerCase().includes("calicinet") && data[selectedRegion.rows[1]]?.confirmed) ||  
                                               (!data[selectedRegion.rows[1]]?.organization?.toLowerCase().includes("calicinet") && data[selectedRegion.rows[1]]?.published)) && 
                                              field.access !== "lab_only_field" // disable editing cells for published row for PN and for confirmed row for CN
              let _canEditField = canEdit && canEditField(access, !!selectedView?.nationalDatabase) && !isPublishedOrConfirmed
              if (!_canEditField) return;

              newCellEdits[dataKey] = {
                value: pastedCells[0][0], // only paste the first single value 
                path: isComparison ? fieldList[selectedRegion.cols[1]].path : getViewableColumns[selectedRegion.cols[1]].path,
                rowIndex: selectedRegion.rows[1],
                error
              }

              if (copiedCells) {
                resetBorders(copiedCells.leftmostIndex, copiedCells.rightmostIndex, copiedCells.topmostIndex, copiedCells.bottommostIndex, activeIndex);
              }

            } else {
              let leftmostIndex = Infinity;
              let rightmostIndex = -Infinity;
              let topmostIndex = Infinity;
              let bottommostIndex = -Infinity;

              for (let i: number = selectedRegion.cols[0]; i <= selectedRegion.cols[1]; i++) {
                for (let j: number = selectedRegion.rows[0]; j <= selectedRegion.rows[1]; j++) {
                  const dataKey = `${i}-${j}`;

                  let field = colFieldRef[i]
                  let error = field.pattern && !(new RegExp(field.pattern).test(pastedCells[0][0])) ? `Invalid format. (eg: ${field.examples?.join(', ')})` : undefined
                  error = !pastedCells[0][0] && field.required ? "This field is required." : error

                  // Check if the cell can be edited. 
                  const access = colFieldRef[i].access || "internal"
                  const isPublishedOrConfirmed = ((data[j]?.organization?.toLowerCase().includes("calicinet") && data[j]?.confirmed) || 
                                                 (!data[j]?.organization.toLowerCase().includes("calicinet") && data[j]?.published)) &&
                                                 field.access !== "lab_only_field" // disable editing cells for published row for PN and confirmed row for CN
                  let _canEditField = canEdit && canEditField(access, !!selectedView?.nationalDatabase) && !isPublishedOrConfirmed
                  if (!_canEditField) continue;

                  newCellEdits[dataKey] = {
                    value: pastedCells[0][0], // only paste the first single value
                    path: ["data.metadata.Checkbox", ...(isComparison || selectedView?.nationalDatabase ? getStatusFieldsForNDB() : getStatusFields()), ...(isComparison ? fieldList.map(v => v.path) : (getViewableColumns.map(v => v.path) ?? []))][i],
                    rowIndex: j,
                    error
                  }

                  leftmostIndex = Math.min(leftmostIndex, i);
                  rightmostIndex = Math.max(rightmostIndex, i);
                  topmostIndex = Math.min(topmostIndex, j);
                  bottommostIndex = Math.max(bottommostIndex, j);

                }
              }

              if (copiedCells && deleteCopied) {
                for (let i = copiedCells.leftmostIndex; i <= copiedCells.rightmostIndex; i++) {
                  for (let j = copiedCells.topmostIndex; j <= copiedCells.bottommostIndex; j++) {
                    newCellEdits[`${i}-${j}`] = {
                      value: "",
                      path: ["data.metadata.Checkbox", ...(isComparison || selectedView?.nationalDatabase ? [] : ["data.metadata._Status", "data.metadata.Published"]), ...(isComparison ? fieldList.map(v => v.path) : (getViewableColumns.map(v => v.path) ?? []))][i],
                      rowIndex: i,
                    }
                  }
                }
                resetBorders(copiedCells.leftmostIndex, copiedCells.rightmostIndex, copiedCells.topmostIndex, copiedCells.bottommostIndex, activeIndex);
              }
            }

          }

        });

      let oldCellEdits = cloneDeep(activeCellEdits);
      var newItem = { type: 'multipaste', cellEdits: oldCellEdits, newData: newCellEdits };
      editHistory.push(newItem);

      // For performance reasons and to avoid an infinitely growing array, only store the last 20 changes
      while (editHistory.length > 20) {
        editHistory.shift();
      }
      //reset stored data for "ctrl+y"
      redoUndone_editHistory = [];

      !isComparison ? setCellEdits(newCellEdits) : setCellEditsComparison(newCellEdits);
    }
  };

  const drawBorders = (leftmostIndex: number, rightmostIndex: number, topmostIndex: number, bottommostIndex: number) => {
    if (leftmostIndex !== Infinity && rightmostIndex !== -Infinity && topmostIndex !== Infinity && bottommostIndex !== -Infinity) {
      const targetClass = getNestedChild(editDataTableElement, 0, 4, 0, 1, 1, 0, 0, 0, 0)?.getAttribute('class') ?? getNestedChild(editDataTableElement, 0, 4, 0, 1, 0, 0, 0, 0, 0)?.getAttribute('class');
      if (copiedCells) {
        resetBorders(copiedCells.leftmostIndex, copiedCells.rightmostIndex, copiedCells.topmostIndex, copiedCells.bottommostIndex, activeIndex);
      }

      for (let i: number = leftmostIndex; i <= rightmostIndex; i++) {
        for (let j: number = topmostIndex; j <= bottommostIndex; j++) {
          const targetClassSplited = targetClass?.replace(new RegExp(`(.*?-cell-row-)[0-9]+`), `$1${j}`)
            .replace(new RegExp(`(.*?-cell-col-)[0-9]+`), `$1${i}`)
            .split(' ');

          // checks for a dash followed by one or more digits at the end
          const regexEndsWithNumber = /-\d+$/;
          let targetClass2 = targetClassSplited?.filter((className: string) => regexEndsWithNumber.test(className)).join(' ');

          if (targetClass2) {
            const cell = document.getElementsByClassName(targetClass2)[activeIndex] as HTMLElement;
            if (i === leftmostIndex && cell) cell.style.borderLeft = "2px dashed #2d72d2";
            if (i === rightmostIndex && cell) cell.style.borderRight = "2px dashed #2d72d2";
            if (j === topmostIndex && cell) cell.style.borderTop = "2px dashed #2d72d2";
            if (j === bottommostIndex && cell) cell.style.borderBottom = "2px dashed #2d72d2";
          }
        }
      }
    }
  }

  const calicinetNationalColWidths = (selectedView?.isOutbreak || isComparison) ? [40] : [40, 40, 140]
  const calicinetLabColWidths = selectedView?.isOutbreak ? [40, 40] : [40, 40, 40, 40, 40, 140]
  const pulsenetNationalColWidths = [40]
  const pulsenetLabColWidths = [40, 40, 40, 40, 40]
  const columnWidths: (number | undefined)[] = organization?.organizationName.toLowerCase().includes("calicinet") ? (isComparison || selectedView?.nationalDatabase ? calicinetNationalColWidths : calicinetLabColWidths) :
    (isComparison || selectedView?.nationalDatabase ? pulsenetNationalColWidths : pulsenetLabColWidths); // First two widths are for Checkbox and Status columns
  if (organization?.organizationName.toLowerCase().includes("calicinet")) {
    for (let i = isComparison || selectedView?.nationalDatabase ? calicinetNationalColWidths.length : calicinetLabColWidths.length; i < colNames.length; i++) {
      columnWidths.push(undefined);
    }
  } else {
    for (let i = isComparison || selectedView?.nationalDatabase ? pulsenetNationalColWidths.length : pulsenetLabColWidths.length; i < colNames.length; i++) {
      columnWidths.push(undefined);
    }
  }

  const getFrozenCols = () => {
    // Check if 'GroupName' exists in fieldList
    const groupNameExists = fieldList.find(i => i.name === 'GroupName') !== undefined
    let index
    if (organization?.organizationName.toLowerCase().includes("calicinet")) {
      if (isComparison || selectedView?.nationalDatabase)
        index = selectedView?.isOutbreak ? 2 : (isComparison ? 2 : 4)
      else
        index = selectedView?.isOutbreak ? 3 : 7
    } else
      index = isComparison || selectedView?.nationalDatabase ? 2 : 6
    return groupNameExists ? index + 1 : index;
  }

  const lastRefecth = useRef(new Date())
  const handleVisibleCellChange = (rowIndices: RowIndices, columnnIndices: ColumnIndices) => {
    if (isComparison || new Date().getTime() - lastRefecth.current.getTime() < 50) {
      return
    }
    if (queryHasNextPage && !queryIsFetching && (rowIndices.rowIndexEnd + 1 === numRows || isExportDialogOpen)) {
      queryFetchNextPage()
      lastRefecth.current = new Date()
    }
  }
  const lastHighlightedIndex = useRef<number>()

  return columns?.length && (!queryIsLoading || (fullComparison && currentTab?.sampleData)) ?
    <>
      <HotkeysProvider>
        {
          queryIsLoading && !fullComparison ?
            <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Spinner />
            </div>
            :
            <div
              style={{ height: "100%" }}
              ref={tableWrapperRef}
              onKeyUp={(event) => {
                if (event.key === "Shift" && lastHighlightedIndex.current && !updateHasRunForShift.current) {
                  updateSelections(data[lastHighlightedIndex.current], undefined, true)
                  lastHighlightedIndex.current = undefined
                }
                updateHasRunForShift.current = false;
              }}>
              <Table2
                numRows={numRows}
                cellRendererDependencies={[state.sortedIndexMap, activeCellEdits, samples]}
                enableFocusedCell={true}
                loadingOptions={showLoading ? [TableLoadingOption.CELLS] : [TableLoadingOption.CELLS]}
                className={tableId ? "editDataTable " + tableId : "editDataTable"}
                ref={tableRef}
                columnWidths={columnWidths}
                enableRowResizing={false}
                numFrozenColumns={getFrozenCols()}
                numFrozenRows={0}
                renderMode={RenderMode.BATCH_ON_UPDATE}
                defaultRowHeight={isComparison ? 30 : 20}
                onCompleteRender={() => {
                  if (activeNewEdits.length) {
                    const newState = {
                      ...state,
                      loadingCells: []
                    };
                    setState(newState);
                    !isComparison ? setNewEdits([]) : setNewEditsComparison([]);
                  }

                  const PreviousData = isComparison && currentTab ? previousDataForComparison : previousData
                  const needUpdate = isComparison && currentTab ? true : updatedKeysForEditing.needUpdate
                  if(Object.keys(activeCellEdits).length && PreviousData && needUpdate){
                    updateCellEdits(PreviousData);
                  }
                }}
                onVisibleCellsChange={handleVisibleCellChange}
                onFocusedCell={(focusedCell) => {
                  lastSelectedIndex.current = focusedCell.row
                  const cellData = getCellData(focusedCell.row, focusedCell.col)
                  if (cellData.type === "input") {
                    const cell = document.querySelectorAll(`.bp5-table-cell-row-${focusedCell.row}.bp5-table-cell-col-${focusedCell.col}`)[0]
                    if (cell) {
                      cell.querySelector("input")?.focus()
                    }
                  }
                }}
                onSelection={(regions) => {
                  const rows = regions?.[0]?.rows
                  if (rows && rows.length === 2 && rows[0] !== rows[1]) {
                    if (rows[0] < lastSelectedIndex.current) {
                      lastHighlightedIndex.current = rows[0]
                    } else if (rows[1] > lastSelectedIndex.current) {
                      lastHighlightedIndex.current = rows[1]
                    }
                  }
                }}
              >
                {columns}
              </Table2>
            </div>
        }

      </HotkeysProvider >
      <div>
        <input
          style={{
            position: 'fixed',
            left: '50%',
            top: '50%',
            opacity: 0,
            pointerEvents: 'none'
          }}
          ref={hiddenInputRef}
          onPaste={(event) => handlePasteEvent(event, false)}
        />
        <input
          style={{
            position: 'fixed',
            left: '50%',
            top: '50%',
            opacity: 0,
            pointerEvents: 'none'
          }}
          ref={hiddenMultiInputRef}
          onPaste={(event) => handleMultiPasteEvent(event, false)}
        />
      </div>
    </> :
    <>
      <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spinner />
      </div>
    </>
}
export default React.memo(EditDataViewer)

export const getDataForPreviousData = () => dataForPreviousData

export function resetBorders(leftmostIndex: number, rightmostIndex: number, topmostIndex: number, bottommostIndex: number, activeIndex: number): void {
  if (leftmostIndex !== Infinity && rightmostIndex !== -Infinity && topmostIndex !== Infinity && bottommostIndex !== -Infinity && copiedCells) {
    const targetClass = getNestedChild(editDataTableElement, 0, 4, 0, 1, 1, 0, 0, 0, 0)?.getAttribute('class') ?? getNestedChild(editDataTableElement, 0, 4, 0, 1, 0, 0, 0, 0, 0)?.getAttribute('class');
    for (let i: number = copiedCells.leftmostIndex; i <= copiedCells.rightmostIndex; i++) {
      for (let j: number = copiedCells.topmostIndex; j <= copiedCells.bottommostIndex; j++) {
        const targetClassSplited = targetClass?.replace(new RegExp(`(.*?-cell-row-)[0-9]+`), `$1${j}`)
          .replace(new RegExp(`(.*?-cell-col-)[0-9]+`), `$1${i}`)
          .split(' ');

        // checks for a dash followed by one or more digits at the end
        const regexEndsWithNumber = /-\d+$/;
        let targetClass2 = targetClassSplited?.filter((className: string) => regexEndsWithNumber.test(className)).join(' ');

        if (targetClass2) {
          const cell = document.getElementsByClassName(targetClass2)[activeIndex === 0 ? activeIndex : document.getElementsByClassName(targetClass2)?.length - 1] as HTMLElement;
          if (cell && i === copiedCells.leftmostIndex && cell) cell.style.borderLeft = "0px";
          if (cell && i === copiedCells.rightmostIndex && cell) cell.style.borderRight = "0px";
          if (cell && j === copiedCells.topmostIndex && cell) cell.style.borderTop = "0px";
          if (cell && j === copiedCells.bottommostIndex && cell) cell.style.borderBottom = "0px";
        }
      }
    }
  }
}